import React from "react";

const Certs = () => {
	return (
		<div id="b1" style={{
			backgroundColor: '#efeed8'
		}}>
		{/* <div id="b1" style={{
			backgroundImage: `url(${process.env.PUBLIC_URL + `/images/bkg2.jpg`})`
		}}></div> */}
			<div className="header-spacing"></div>
			<div className="aboutpage-con">
			
			<div className="about-con">
				<div className="title">CERTIFICATIONS</div>
				<div id="abt">
                <div class="timeline">
                     <div class="timeline-entry">
                        <h3>July 2024</h3>
                        <p>Kubernetes and Cloud Native Associate (KCNA)<br />Linux Foundation</p>
                    </div>
                    <div class="timeline-entry">
                        <h3>2023 - present</h3>
                        <p>Bachelor of Computer Science<br />(Cyber Security & Digital Systems Security)<br />University of Wollongong<br />Singapore Institute of Management</p>
                     </div>
                     


                    <div class="timeline-entry">
                        <h3>September 2020</h3>
                        <p>Certified Ethical Hacker (CEHv10)<br />EC-Council</p>
                    </div>
                    <div class="timeline-entry">
                        <h3>March 2020</h3>
                        <p>Organisational Systems Security Analyst (OSSA)<br />Thinksecure</p>
                    </div>
                    <div class="timeline-entry">
                        <h3>2019 - 2021</h3>
                        <p>Diploma in Infocomm Security Management (DISM)<br />Singapore Polytechnic (SP)</p>
                    </div>
                </div>

					

				</div>
			</div>
			</div>
			

			
		
		</div>
	);
};

export default Certs;
